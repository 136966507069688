import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "./Theme";
import ResizableImage from "./ResizableImage";
import SocialMediaIcon from "./SocialMediaIcon";
import { Telegram, WhatsApp } from "@mui/icons-material";
import NavButton from "./NavButton";
import LanguageSwitch from "./LanguageSwitch";
import { useHistory, useLocation } from "react-router-dom";

const NavBar: React.FC<{ textColor?: string }> = ({ textColor }) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [iconWidth, setIconWidth] = useState<number>(0);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setIconWidth(70);
    } else {
      setIconWidth(scrolled ? 115 : 135);
    }
  }, [isMobile, scrolled]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleChangeLanguage = (languageCode: string) => {
    setCurrentLanguage(languageCode);
    i18n.changeLanguage(languageCode);

    let cleanPath = location.pathname.replace(/^\/(en|ru)?(\/.*)?$/, '$2');
    const newPath = languageCode === 'en' ? cleanPath : `/${languageCode}${cleanPath}`;
    history.push(newPath);
  };

  const buttons = [
    { text: t("navbar.Home"), route: currentLanguage === "en" ? "/" : `/${currentLanguage}` },
    { text: t("navbar.Why us"), route: currentLanguage === "en" ? "/approach" : `/${currentLanguage}/approach` },
    { text: t("navbar.Program"), route: currentLanguage === "en" ? "/our-program" : `/${currentLanguage}/our-program` },
    { text: t("navbar.Pricing"), route: currentLanguage === "en" ? "/pricing" : `/${currentLanguage}/pricing` },
    { text: t("navbar.Enroll"), route: currentLanguage === "en" ? "/enroll" : `/${currentLanguage}/enroll` },
  ];
  
  const drawer = (
    <Box 
      sx={{ width: 250 }} 
      role="presentation"
      //onClick={handleDrawerClose}
      onKeyDown={handleDrawerClose}>
      <List>
        {buttons.map((buttonData) => (
          <ListItem key={buttonData.route}>
            <NavButton
              route={buttonData.route}
              children={buttonData.text}
              textColor={"black"}
            />
          </ListItem>
        ))}
        <ListItem>
          <LanguageSwitch
            currentLanguage={currentLanguage}
            onChange={handleChangeLanguage}
            isMobile={isMobile}
          />
        </ListItem>
        <ListItem>
          <SocialMediaIcon href={"https://t.me/+381606062011"}>
            <Telegram color={"warning"} fontSize={"large"} />
          </SocialMediaIcon>
          <SocialMediaIcon href={"https://wa.me/381606062011"}>
            <WhatsApp color={"warning"} fontSize={"large"} />
          </SocialMediaIcon>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box
      alignItems={scrolled ? "center" : "top"}
      padding={isMobile || scrolled ? "8px" : "25px"}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: scrolled ? "white" : "transparent",
        transition: "all 0.3s ease-in-out",
        width: "100%",
      }}
    >
      <Box width={iconWidth}>
        <ResizableImage src="/logo.webp" alt="Primary International School Belgrade | Young Sparrows" />
      </Box>
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{ mr: 2 }}
          >
            <MenuIcon color={scrolled ? "secondary" : "primary"} />
          </IconButton>
          <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
            {drawer}
          </Drawer>
        </>
      ) : (
        <>
          {buttons.map((buttonData) => (
            <NavButton
              key={buttonData.route}
              route={buttonData.route}
              children={buttonData.text}
              textColor={scrolled ? "black" : textColor}
            />
          ))}
          <LanguageSwitch
            currentLanguage={currentLanguage}
            onChange={handleChangeLanguage}
            scrolled={scrolled}
          />
        </>
      )}
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: "fit-content",
            borderRadius: "30px",
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <SocialMediaIcon href={"https://t.me/+381606062011"}>
            <Telegram color={"info"} fontSize={"large"} />
          </SocialMediaIcon>
          <SocialMediaIcon href={"https://wa.me/381606062011"}>
            <WhatsApp color={"info"} fontSize={"large"} />
          </SocialMediaIcon>
        </Box>
      )}
    </Box>
  );
};

export default NavBar;
